/* eslint no-console:0 */
import { HMRCInitAll } from 'dvla-external-frontend';
import { initAll } from 'govuk-frontend';
import formUtils from './js-form-utils';

require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);

// ensure images are included in the compiled pack
require.context('./images', true);

// ensure dvla-external-frontend/dist/media are included in the compiled pack for favicon, fonts etc
require.context('dvla-external-frontend/dist/media', true);

const ReactRailsUJS = require('react_ujs');
// create a context from our app/javascript/components directory
const customContext = require.context('./components', true);
ReactRailsUJS.useContext(customContext);
initAll();
HMRCInitAll();

window.onload = () => {
  formUtils.registerInputListeners();
};
