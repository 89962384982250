export function ProgressiveDisclosure() {
  window.addEventListener('DOMContentLoaded', (event) => {
    // Get the disclosure trigger elements
    const disclosures = Array.prototype.slice.call(
      document.querySelectorAll('[data-disclosure]')
    );

    // Loop through them with Array.forEach()
    disclosures.forEach((disclosure) => {
      let fireOnLoad = true;
      // add the appropriate listener depending on the trigger element, providing it's a form element
      switch (getDisclosureType(disclosure)) {
        case 'radio':
          disclosure.addEventListener('change', disclose);
          fireOnLoad = disclosure.checked;
          break;
        default:
          disclosure.addEventListener('click', disclose);
          break;
      }

      // fire show only on load - since elements will be hidden by default
      if (fireOnLoad) {
        disclose(null, { disclosure, onLoad: true });
      }
    });
  });

  function disclose(event, options = {}) {
    const { disclosure = event.target, onLoad } = options;

    // Only run on elements that have the [data-disclosure] attribute
    // If the disclosure doesn't have the attribute, return
    if (!disclosure.hasAttribute('data-disclosure')) return;

    // Get the content associated with the button
    const content = document.querySelector(
      `#${disclosure.getAttribute('aria-controls')}`
    );

    // No point continuing without content
    if (!content) {
      console.log(
        `Content couldn't be found for '#${disclosure.getAttribute(
          'aria-controls'
        )}`
      );
      return;
    }

    let showContent = false;
    switch (getDisclosureType(disclosure)) {
      case 'radio':
        // if we have a radio button, we also need to know what value will trigger a show
        var disclosureValue = null;
        if (disclosure.hasAttribute('data-disclosure-value')) {
          disclosureValue = disclosure.getAttribute('data-disclosure-value');
        }
        if (!disclosureValue) {
          console.log('No disclosure value specified for radio disclosure');
        }

        // show content if disclosure value == the value of the input disclosure
        showContent = disclosure.value == disclosureValue;
        break;
      default:
        // If the content is visible, hide it
        // Otherwise, show it
        showContent = disclosure.getAttribute('aria-expanded') === 'true';
        break;
    }

    if (showContent) {
      disclosure.setAttribute('aria-expanded', true);
      content.classList.remove('hidden');
    } else {
      // sometimes we only want to process showing the content, not hiding it.
      disclosure.setAttribute('aria-expanded', false);
      content.classList.add('hidden');

      // clear data entered into fields which will now be hidden
      clearElements(content);
    }
  }

  function getDisclosureType(disclosure) {
    switch (disclosure.tagName.toLowerCase()) {
      case 'input':
        return disclosure.type.toLowerCase();
      default:
        return disclosure.tagName.toLowerCase();
    }
  }

  function clearElements(content) {
    getElementsInContainer(content).forEach((element) => {
      element.value = '';
    });
  }

  function getElementsInContainer(content) {
    let elements = [];
    if (content) {
      elements = Array.from(
        content.querySelectorAll('input, select, textarea')
      );
    }
    return elements;
  }
}
